.landingPage{
    height: 100vh;
    width: 100vw;
    overflow: auto;
    padding-top: 7em;
    position: relative;

}
.accountButtons {
    position: fixed;
    right: 0;
    margin: 50px;
    top: 0;
    display:flex;
    gap: 1vw;
    justify-content: center;
    align-items: center;
}

.hamburger-react {
    z-index: 20;
}
  
  .menuNav.showMenu {
    width: 100%;
  }
  
  .menuNav li:first-child {
    margin-top: 7rem;
  }
body {
    padding: 0 !important;
    margin: 0;
}

.searchAreaLogo{
    font-size: 5em;
    font-weight: bold;
    text-align: center;
    color: var(--blue);
    margin: 1em;
    margin-bottom: 0.6em;
}

.searchAreaLandingPage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.landingPageSearchInput{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.landingPageButton{
    margin: 35px 16px 0px 16px;
    width: 130px;
    font-size: large;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profilePreview {
    width:100vw;
    display: flex;
    gap: 4vw;
    margin-top: 5vh;
    align-items: flex-start;
    justify-content: center;
}
.profilePreview > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    word-wrap: break-all;
    width: 10%;
}
@media(max-width: 740px) {
    .landingPageSecondaryButton  {
        margin: 30px 16px 0px 16px !important;
    }
    .profilePreview > div {
        width: 15%;
    }
    .accountButtons {
        margin: 20px;
    }
    .accountButtons button {
      }
}