.mobileInput{
    z-index: 60;
    position: fixed;
    top:0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    background-color: white;
    overflow-y:scroll;
}

.mobileHeader{
    display: flex;
    flex-wrap: nowrap;
    border-bottom: 1px solid lightgray;
}
.mobileHeaderLeft{
    display: flex;
    padding-left: 8px;
    align-items: center;
}

.mobileHeaderRight{
    flex: 1;
}

.mobileHeaderInput{
    margin: 0px;
    height: 60px;
    padding-left: 12px;
}
.mobileInputSuggestions {
    padding: 5px;
    display:flex;
    flex-direction: column;
    gap: 5vh;
    overflow-y:scroll;
}