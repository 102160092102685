.signupForm {
    width: 100%;

}
#signup {
    display:flex;
    justify-content: center;
    align-items: center;
}
.signupChoice {
    margin-top: 100px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.signupCard {
    width: 80vw;
}
@media screen and (max-width: 740px){
    .signupCard {
        width: 100vw;
    }
    .formVoter {
        width: 100% !important; 
        padding: 2px;
    }
}
.formVoter {
        width: 75%;
        padding: 10px;
}