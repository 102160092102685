.electorCard img {
    width: 100px;
    border-width: 1px;
    border-color: black;
    margin: auto;
}
.electorIncumbent img {
    width: 100px;
    border-width: 1px;
    border-color: black;
    margin: auto;
}
.electorGrid {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, max(250px, 100%/6)), 1fr));
    grid-auto-columns: auto;
    gap: 3vw;
    padding: 1%;
    text-align: center;
    justify-items: center;
}
.electorCard {
    text-align: center;
    transition: transform .5s;
    cursor: pointer;
}
.electorCard:hover, .electorIncumbent:hover {
    transform: scale(110%);
}
.electorIncumbent {
    width: 20%;
    transition: transform .5s;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    word-break:break-all;
    text-align: center;
}
.electionContainer h1 {
    margin: 50px 0 50px 0;
}
@media ( max-width: 900px){
     .electorIncumbent {
        width: 250px;
     }
}