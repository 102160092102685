.header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    z-index: 20;
    padding: 1%;
    gap: 3vw;
    display:flex;
    border-bottom: 1px solid lightgrey;
}
#searchLink {
    color: black;
    user-select: none;
}
#searchLink > img {
    margin-right: 20px;
}
.searchResults {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    z-index: 1;
    padding: 1px;
    background-color: white;
    border: 1px solid lightgrey;
    padding: 10px;
    display:flex;
    flex-direction: column;
    gap: 2vh;
}
.searchWrapper {
    position: relative;
    width: 60%;
    justify-self: center;
    margin: auto;
}
.searchWrapper input {
}
@media(max-width: 740px) {
    .searchWrapper {
        width: 80%;
    }
    .searchResults  {
    }
}
.drawerWrapper {
    
}
.flagIcon {
    width: 40px !important;
    margin-right: 10px;
}
.searchResults img {
    width: 50px;
    height: auto;
}
.header img {
    padding: 2%;
}
.mobileHeaderRight .ant-input {
    height: 60px;
}
