body {
    padding: 1%;
}
html {
    font-size: 20px;
}
.profileWrapper {
    width: 100%;
    display:flex;
    justify-content: center;
}
.sideBar {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 20%;
    height: 100%;
    position: fixed;
    left: 0;
    padding-top: 5rem;
}

.profile {
    width: 60%;
    height: 100%;
    background-color: white;
    display:flex;
    flex-direction: column;
    gap: 5vh;
    padding-bottom: 10vh;
}
.sideBar h1:hover, .profileTabs h1:hover {
    text-decoration: underline;
    cursor: pointer;
}
.sideBar h1 {
    font-size: 2rem;
}
.profileTabs {
    display: flex;
    justify-content: space-around;
}
p > a {
    color: black;
}
.profileHeaderWrapper > div:first-of-type{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
}
.commentWrapper {
    display:flex;
    align-items: flex-end;
}
#commentContent {
    display:flex;
    justify-content: space-between;
}
#commentContent img {
    cursor: pointer;
}
.commentWrapper button {
    position: relative;
    left: -33px;
    top: -1px;
}
.headerButtons {
    display:flex;
    flex-direction: column;
    gap: 1vh;
    align-items: center;
    
}
.profileHeaderWrapper {
    display:flex;
    flex-direction: column;
    gap: 1vh;
    padding: 1%;
}
.profileHeaderWrapper h1 {
    font-size: 2em;
}
.viewComments {
    color: grey;
    cursor: pointer;
}

@media screen and (max-width: 740px) {
    .profile {
        width: 100%;
        height: 100%;
        background-color: white;
    }
    .modal > div {
        width: 80vw !important;
        height: 100vw !important;
        font-size:3vw;      
    }
    #logo {
        width: 100px !important                                               ;
    }
    .profileHeaderWrapper > div:first-of-type{
        flex-direction: column;
        gap: 3vh;
    }
    .headerButtons {
        flex-direction: row;
    }
    .profileCard p {
        font-size: .7rem;
    }
}
.bottomTabs {
    position: fixed;
    bottom: 0;
    background-color: #6d028d;
    height: 5vh;
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 3%;
}
.bottomTabs h1 {
    color: white;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.modal {
position:fixed;
left: 0;
bottom: 0;
top: 0;
right: 0;
display:flex;
align-items: center;
justify-content: center;
z-index: 20;
    background-color: rgba(128, 128, 128, 0.6);
}
.ant-tag {
    height: 20px !important;
}
#googleplay{
    width: 200px;
}
#logo {
    width: 150px;
}
.modal > div {
    background-color: white;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 0vh;
    padding: 2%;
    width: 500px;
    border-radius: 12px;
    word-wrap: break-word;
    box-shadow: 10px 10px 10px 10px #8888;
}
.modal h1 {
    margin: 0 !important;
}
.modal textarea {
    padding: 2%;
    width: 80%;
}
.modal button {
    margin: 0 !important;
}