.grecaptcha-badge{
    visibility: hidden;
}

.login{
    display: flex;
    align-items: center;
    justify-content: center;
 }
 
 .loginModal{
     display: flex;
     height: 100vh;
     width: 100vw;
     align-items: center;
     justify-content: center;
     /* background-color: rgba(0,0,0,0.5);  */
 }
 
 .loginModalForm{
     padding: 2em 16px 0px 16px;
     height: 37em;
     text-align: center;
     width:25em;
     background-color: white;
     border-radius: 12px;
     /* border: 1px solid rgba(0,0,0,0.5); */
 }
 .loginModalForm > a > h1{
     color: #6d028d;
     font-weight: bold;
 }
 
 .loginModalForm{
     color: black;
 }
 .forgotPass {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30vh;
 }
 .resetPass {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30vh;
    flex-direction: column;
 }
 .forgotCard {
    width: 500px;
 }
 .forgotPass > input {
    width: 30%;
 }
 .ant-form{ 
     width: 100%;
     align-items: center;
     color: black;
     font-weight: 500;
     transition: all 0.3s ease-in;
 }
 
 
 .ant-input{
     /* margin: 12px 12px 0px 12px; */
     height: 32px;
    
 }

 .registerLinkBtn {
     margin-top: 32px !important;
 }

 /* .ant-form-item-explain{
     color: rgb(250, 52, 52);
     font-size: small;
     text-align: start;
     padding-left: 2em;
 } */


 .loginModalFormOptions{
     display: flex;
     justify-content: space-around;
     width: 100%;
     margin-top: -16px;
     margin-bottom: 16px;
     /* padding: 20px 20px 20px 20px;  */
 }
 .loginModalFormOptions > a{
     text-decoration: none;
     font-size: small;
     color: black;
 }

 .loginModalFormOptions > label > span {
     color: black;
     visibility: hidden;
 }
 
 .formOptionBtn{
     color: black;
 }
 
 hr{
     z-index: 1;
     color: wheat;
     width: 80%;
 }
 
 .formSubmitBtn{
     /* margin-top: 20px; */
     margin-bottom: 0px;
     padding: 0px !important;
     height: 32px !important;
     cursor: pointer;
 }
 
 .loginModalForm > a{
     text-decoration: none;
     color: white;
 }

 .displayErrorDiv{
     color: rgb(250, 52, 52);
     font-weight: bold;
 }

 .hideErrorDiv{
     display: none;
 }