.settings {
 width: 50%;
 margin: auto;   
 padding: 5%;
 margin-top: 5rem;
}
form p{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
@media screen and (max-width: 700px) {
    .settings {
        width: 90%;
       }
    .settings .sideBar {
        width: 100%;
        flex-direction: row;
        position:unset;
        gap: 1rem;
        font-size: 12px;
        justify-content: center;
    }
}
.settingsButtons {
    display:flex;
    justify-content: space-around;
}